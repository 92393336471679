import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaEnvelope, FaMobileAlt } from "react-icons/fa";

const Footer = () => {
    const email = "info@bambinolier.be";
    const phone = "+32 494 750 521";
    const facebook = "https://www.facebook.com/profile.php?id=61554948504725";
    const instagram = "https://www.instagram.com/bambinolier/";

    return (
        <div className="footer container">
            <div className="gridItem">
                <h3>Contact</h3>
                <div className="contact">
                    <div className="flex-align-middle">
                        <FaMobileAlt className="contactIcon" />
                        <Link to={`tel:${phone}`}>{phone}</Link>
                    </div>
                    <div className="flex-align-middle">
                        <FaEnvelope className="contactIcon" />
                        <Link to={`mailto:${email}`}>{email}</Link>
                    </div>
                </div>
            </div>
            <div className="gridItem center">
                <h3>Sociaal</h3>
                <div className="flex-row">
                    <Link to={facebook} target="_blank"><FaFacebook className="socialIcon" /></Link>
                    <Link to={instagram} target="_blank"><FaInstagram className="socialIcon" /></Link>
                </div>
            </div>
            <div className="gridItem">
                <div className="locatie">
                    <h3>Locatie</h3>
                    <address>A. Vanderpoortenlaan 17 bus 2</address>
                    <address>2500 Lier</address>
                </div>
            </div>
        </div>
    )
};

export default Footer;