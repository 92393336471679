import { useRef, useState, useEffect } from "react";
import axios from '../../api/axios';
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { FaInfoCircle, FaCheck, FaTimes } from "react-icons/fa";

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const GSM_REGEX = /^([0|\+[0-9]{1,5})?([0-9]{9})$/;
const PROFILE_URL = '/profiel';

const ProfileLayout = ({ title = "Register", handleSetPage = null }) => {
    // Common usage
    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [gsm, setGsm] = useState('');
    const [validGsm, setValidGsm] = useState(false);
    const [gsmFocus, setGsmFocus] = useState(false);

    const [newsletter, setNewsletter] = useState(false);
    const [publiPhoto, setPubliPhoto] = useState(true);

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setValidGsm(GSM_REGEX.test(gsm));
    }, [gsm])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd, matchPwd])


    // Profile Update
    const [deletePermanently, setDeletePermanently] = useState(false);
    const logout = useLogout();
    const { auth } = useAuth();

    useEffect(() => {
        const getProfile = async () => {
            try {
                const response = await axios.get(PROFILE_URL, {
                    headers: { 'Authorization': `Bearer ${auth.accessToken}` }
                });

                setFirstname(response?.data?.firstname);
                setLastname(response?.data?.lastname);
                setGsm(response?.data?.gsm);
                setEmail(response?.data?.email);
                setNewsletter(response?.data?.newsletter);
                setPubliPhoto(response?.data?.publi_photo);

            } catch (err) {
                await logout();
            }
        }
        if (title !== "Register") {
            getProfile();
        }
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (deletePermanently) {
            try {
                await axios.delete(PROFILE_URL, {
                    headers: { 'Authorization': `Bearer ${auth.accessToken}` }
                });
            } catch (err) {
                setErrMsg(err.message);
            } finally {
                setErrMsg('Uw account en alle gegevens zijn verwijderd!');

                setTimeout(async () => {
                    await logout();
                }, 3000);
            }
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (title === "Register") {
            // Register Page

            // if button enabled with JS hack
            const v1 = EMAIL_REGEX.test(email);
            const v2 = PWD_REGEX.test(pwd);
            const v3 = GSM_REGEX.test(gsm);
            if (!v1 || !v2 || !v3) {
                setErrMsg("Ongeldige input");
                return;
            }

            try {
                const response = await axios.post(PROFILE_URL,
                    JSON.stringify({ firstname, lastname, email, gsm, pwd, newsletter, publiPhoto }),
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
                setErrMsg(response?.data?.success);
                setTimeout(
                    () => handleSetPage(e)
                    , 3000);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Geen antwoord van de Server');
                } else if (err.response?.status === 409) {
                    setErrMsg('E-mail adres is al in gebruik');
                } else {
                    setErrMsg('Registratie mislukt')
                }
                errRef.current.focus();
            }
        } else {
            // Profile Update page

            try {
                const response = await axios.put(PROFILE_URL,
                    JSON.stringify({ firstname, lastname, email, gsm, pwd, newsletter, publiPhoto }),
                    {
                        headers: {
                            'Authorization': `Bearer ${auth.accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                setErrMsg(response?.data?.success);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Geen antwoord van de Server');
                } else if (err.response?.status === 409) {
                    setErrMsg('E-mail adres is al in gebruik');
                } else {
                    setErrMsg('Profiel update mislukt')
                }
                errRef.current.focus();
            }
        }

    }

    return (
        <div className="no-color">
            <p ref={errRef}>{errMsg}</p>
            <h3>{title}</h3>
            <form onSubmit={handleSubmit}>
                <span className="validation">
                    <FaCheck className={validEmail ? "valid" : "hide"} />
                    <FaTimes className={validEmail || !email ? "hide" : "invalid"} />
                </span>
                <input
                    ref={emailRef}
                    required
                    type="email"
                    placeholder="E-mail adres"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                />
                <p className={emailFocus && !validEmail ? "instructions" : "offscreen"}>
                    <FaInfoCircle /> Dit is geen geldig e-mail adres.
                </p>
                <fieldset>
                    <legend>
                        {title === "Register"
                            ? "Kies een wachtwoord"
                            : "Enkel als u wilt wijzigen"}
                    </legend>
                    <span className="validation">
                        <FaCheck className={validPwd ? "valid" : "hide"} />
                        <FaTimes className={validPwd || !pwd ? "hide" : "invalid"} />
                    </span>
                    <input
                        required={title === "Register"}
                        className={title === "Register" ? "" : "validation"}
                        type="password"
                        placeholder="Wachtwoord"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                    />
                    <p className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        <FaInfoCircle /> 8 to 24 tekens.<br />
                        Moet bestaan uit hoofdletters en kleine letters, een nummer en een speciaal teken.<br />
                        Toegestane speciale tekens: ! @ # $ %
                    </p>
                    <span className="validation">
                        <FaCheck className={validMatch && matchPwd ? "valid" : "hide"} />
                        <FaTimes className={validMatch || !matchPwd ? "hide" : "invalid"} />
                    </span>
                    <input
                        required={title === "Register"}
                        className={title === "Register" ? "" : "validation"}
                        type="password"
                        placeholder="Bevestig wachtwoord"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd}
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                    />
                    <p className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        <FaInfoCircle /> Wachtwoorden komen niet overeen.
                    </p>
                </fieldset>
                <span className="validation">
                    <FaCheck className={firstname ? "valid" : "hide"} />
                </span>
                <input
                    required
                    type="text"
                    placeholder="Voornaam"
                    onChange={(e) => setFirstname(e.target.value)}
                    value={firstname}
                />
                <span className="validation">
                    <FaCheck className={lastname ? "valid" : "hide"} />
                </span>
                <input
                    required
                    type="text"
                    placeholder="Achternaam"
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname}
                />
                <span className="validation">
                    <FaCheck className={validGsm ? "valid" : "hide"} />
                    <FaTimes className={validGsm || !gsm ? "hide" : "invalid"} />
                </span>
                <input
                    required
                    type="tel"
                    placeholder="Contactnummer (Mobiel)"
                    onChange={(e) => setGsm(e.target.value)}
                    value={gsm}
                    onFocus={() => setGsmFocus(true)}
                    onBlur={() => setGsmFocus(false)}
                />
                <p className={gsmFocus && !validGsm ? "instructions" : "offscreen"}>
                    <FaInfoCircle /> Dit is geen geldig nummer.
                </p>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        id="newsletter"
                        onChange={(e) => setNewsletter(e.target.checked)}
                        checked={newsletter}
                    />
                    <label htmlFor="newsletter">Ontvang onze nieuwsbrief via e-mail.</label>
                </div>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        id="publiPhoto"
                        onChange={(e) => setPubliPhoto(e.target.checked)}
                        checked={publiPhoto}
                    />
                    <label htmlFor="publiPhoto">Ik geef <strong>Bambino</strong> toestemming, om foto's gemaakt tijdens de activiteiten te gebruiken voor publiciteit.</label>
                </div>
                {title === "Register" ?
                    <>
                        <input disabled={![validEmail, validPwd, validMatch, firstname, lastname, validGsm].every(el => Boolean(el) === true)} type="submit" value="Registreren" />
                        <p>Na registratie zullen uw persoonlijke data worden gebruikt om uw gebruikservaring te verbeteren, u aan te melden op onze website en voor andere doeleinden zoals beschreven in ons <Link>privacybeleid</Link>.</p>
                    </>
                    :
                    <input disabled={![validEmail, validPwd || !pwd, validMatch, firstname, lastname, validGsm].every(el => Boolean(el) === true)} type="submit" value="Opslaan" />
                }
            </form>
            {title === "Register" ?
                <p>Heb je al een account? <Link onClick={handleSetPage}>Meld je hier aan</Link></p>
                :
                <p>
                    <form onSubmit={handleDelete}>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="delete"
                                checked={deletePermanently}
                                onChange={(e) => setDeletePermanently(e.target.checked)}
                            />
                            <label htmlFor="delete">Verwijder mijn account en alle gegevens.</label>
                        </div>
                        <input disabled={!deletePermanently} type="submit" value="JA, Verwijder alles!" />
                    </form>
                </p>
            }
        </div>
    )
};

export default ProfileLayout;