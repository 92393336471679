import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
const KAMPJES_URL = '/kampjes'

const initialState = {
    kampjes: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
};

export const fetchKampjes = createAsyncThunk(
    'kampjes/fetchKampjes',
    async () => {
        const response = await axios.get(KAMPJES_URL);
        return response.data;
    })

const kampjesSlice = createSlice({
    name: 'kampjes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKampjes.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchKampjes.fulfilled, (state, action) => {
                state.status = 'success';
                state.kampjes = action.payload;
            })
            .addCase(fetchKampjes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectAllKampjes = (state) => state.kampjes.kampjes;
export const getKampjesStatus = (state) => state.kampjes.status;
export const getKampjesError = (state) => state.kampjes.error;

export default kampjesSlice.reducer;