import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectAllShopItems, itemRemove, clear, parseDate, getDeadline, clearDeadline } from "./shopItemsSlice";
import { MdOutlineRemoveCircle, MdInfoOutline } from "react-icons/md";
import axios from "../../api/axios";
import Timer from "../../components/utilities/Timer";
import useAuth from "../../hooks/useAuth";
const PAY_URL = "/PayNL";


const ShopCartList = () => {
    const { auth } = useAuth();
    const shopItems = useSelector(selectAllShopItems);
    const deadline = useSelector(getDeadline);
    const dispatch = useDispatch();

    const [errMsg, setErrMsg] = useState('');

    const renderedShoppingCart = shopItems.map(item => (
        <div key={item.id}><div className="btns">
            <div>
                <Link onClick={(e) => handleClickToDelete(e, item)}><MdOutlineRemoveCircle /></Link>
            </div>
        </div>
            <div>{item.title}</div>
            <div>{parseDate(item.from_time)}</div>
            <div className="span"><strong>Deelnemertjes:</strong>
                {
                    item.kids.map((kid, id) => (
                        <div key={id}>
                            <div>{`#${id + 1}: ${kid}`}</div>
                            <div className="price" data-price={parseFloat(item.price).toFixed(2)}></div>
                        </div>
                    ))
                }
            </div>
            <div className="subtotal">
                <div>
                    <div>Subtotaal:</div>
                    <div className="price" data-price={(parseFloat(item.price) * item.kids.length).toFixed(2)}></div>
                </div>
            </div>
        </div>
    ));

    useEffect(() => {
        try {
            // const response = await axios.post(PAY_URL,
            // )
        } catch (err) {

        }
    }, [])

    const navigate = useNavigate();
    const [total, setTotal] = useState();

    const handleExitCart = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleClickToDelete = (e, item) => {
        e.preventDefault();
        dispatch(
            itemRemove(item)
        );
    };

    useEffect(() => {
        let _total = 0;
        shopItems.forEach(item => {
            _total += parseFloat(item.price) * item.kids.length;
        });
        setTotal(_total);

        if (shopItems.length === 0) {
            dispatch(clearDeadline());
        }

    }, [shopItems]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrMsg('');

        try {
            const response = await axios.post(PAY_URL,
                JSON.stringify({ shopItems }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            )
            // navigate(response.data.paymentUrl);
            window.location.replace(response.data.paymentUrl);
        } catch (err) {
            setErrMsg(err.message);
        }
    }

    const handleReachedDeadline = () => {
        dispatch(clear());
        setTimeout(() => {
            navigate(-1);
        }, 2000);
    }


    return (
        <>
            <h2 className="center">Winkelwagentje</h2>
            <div className="container">
                <form onSubmit={handleSubmit} className="cart">
                    <p>
                        Iets vergeten toe te voegen? Of iets wijzigen?<br /><Link onClick={handleExitCart}>Klik hier</Link> om terug te gaan naar de vorige pagina.
                    </p>
                    {deadline &&
                        <div className="timer">
                            <MdInfoOutline />
                            <div>Uw winkelmandje blijft nog <strong><Timer deadline={deadline} handleReachedDeadline={handleReachedDeadline} /></strong> geldig.</div>
                        </div>
                    }
                    <div className="cart-contents">
                        {renderedShoppingCart}
                        {renderedShoppingCart.length === 0 &&
                            <div className="center">Uw winkelmandje is leeg</div>}
                        <div>
                            <div>Totaal:</div>
                            <div className="price" data-price={parseFloat(total).toFixed(2)}></div>
                        </div>
                    </div>
                    {
                        shopItems.length > 0 &&
                        <input type="submit" value="Betalen" />
                    }
                </form>
                <p>{errMsg}</p>
            </div>
        </>
    )
}

export default ShopCartList;