import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { MdHistory, MdLogout, MdEventNote, MdAppSettingsAlt, MdOutlineViewList } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaChildren } from "react-icons/fa6";
import { useEffect, useState } from "react";
import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";

const AccountLayout = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const logout = useLogout();
    const [page, setPage] = useState();

    const handleNav = (to) => {
        navigate(`/account/${to}`);
    };

    const signOut = async () => {
        await logout();
        // Goto Home page
        navigate('/');
    }

    useEffect(() => {
        setPage(location.pathname.replace('/account/', ''));
    }, [location]);

    useEffect(() => {
        if (!(auth.kids && !Object.values(auth.kids[0]).includes(""))) {
            navigate('kids');
        };
    }, []);


    const adminMenu = [
        { icon: <MdOutlineViewList />, title: "Agenda", page: "agenda" },
        { icon: <MdEventNote />, title: "Kampjes", page: "kampjes" },
        { icon: <AiOutlineSchedule />, title: "Kamp Dagen", page: "kampdagen" },
        { icon: <IoNewspaperOutline />, title: "Nieuwsbrief", page: "nieuwsbrief" },
        { icon: <MdAppSettingsAlt />, title: "Instellingen", page: "instellingen" },
    ]
    const renderedAdminMenu = auth?.roles?.find(role => role === 5150) ?
        (
            <div className="accountNav">
                <ul>
                    {
                        adminMenu.map((item, i) => (
                            <li key={i} className={page === `admin/${item.page}` ? 'selected' : ''}
                                onClick={() => handleNav(`admin/${item.page}`)}>
                                <span>{item.icon}</span>
                                <span>{item.title}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
        :
        ""
        ;

    return (
        <div className="accountLayout">
            {renderedAdminMenu}
            <div className="accountNav">
                <ul>
                    <li className={page === 'overzicht' ? 'selected' : ''}
                        onClick={() => handleNav('overzicht')}>
                        <span><MdHistory /></span>
                        <span>Besteloverzicht</span>
                    </li>
                    <li className={page === 'profiel' ? 'selected' : ''}
                        onClick={() => handleNav('profiel')}>
                        <span><ImProfile /></span>
                        <span>Mijn profiel</span>
                    </li>
                    <li className={page === 'kids' ? 'selected' : ''}
                        style={{ color: !(auth.kids && !Object.values(auth.kids[0]).includes("")) ? 'crimson' : '' }}
                        onClick={() => handleNav('kids')}>
                        <span><FaChildren /></span>
                        <span>Kinderen</span>
                    </li>
                    <li onClick={signOut}>
                        <span><MdLogout /></span>
                        <span>Afmelden</span>
                    </li>
                </ul>
            </div>

            <div className="accountPage">
                <Outlet />
            </div>
        </div>
    )
}

export default AccountLayout;