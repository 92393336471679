import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
// import { FaChevronUp } from "react-icons/fa";
import { lazy, Suspense } from "react";
import { AuthProvider } from "../context/AuthProvider";
import { store } from "../app/store";
import { Provider } from "react-redux";

// Load main items
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Loading from "../components/utilities/Loading";
import ScrollToAnchor from "../components/utilities/ScrollToAnchor";
import Account from "../components/Account";
import PersistLogin from "./PersistLogin";
import RequireAuth from "./RequireAuth";
import Unauthorized from "../components/Unauthorized";
import Missing from "../components/Missing";
import ShopCartList from "../features/shoppingCart/ShopCartList";
import CheckOut from "../features/shoppingCart/CheckOut";
import OrderComplete from "../features/shoppingCart/OrderComplete";

// Lazy load pages
const Home = lazy(() => import("../components/pages/Home"));
const Feestjes = lazy(() => import("../components/pages/Feestjes"));
const DropShop = lazy(() => import("../components/pages/DropShop"));
// const Knutseldag = lazy(() => import("../components/pages/Knutseldag"));
const OverOns = lazy(() => import("../components/pages/OverOns"));
const Kampjes = lazy(() => import("../components/pages/Kampjes"));
// const Kampjes = lazy(() => import("../components/pages/Kampjes"));
const KampDetails = lazy(() => import("../components/kampjes/KampDetails"));
// Lazy load account pages
const BestelOverzicht = lazy(() => import("../components/account/BestelOverzicht"));
const Profile = lazy(() => import("../components/account/Profile"));
const Kids = lazy(() => import("../components/account/Kids"));
// Lazy load Admin pages
const AdminAgenda = lazy(() => import("../components/account/admin/Agenda"));
const AdminKamp = lazy(() => import("../components/account/admin/Kampjes"));
const AdminKampDagen = lazy(() => import("../components/account/admin/KampDagen"));

// API base url
const API_URL = "https://rest-api.bambinolier.be/";
// const API_URL = "http://localhost:3500/";
// const API_URL = "http://192.168.0.124:3500/";

// import Login from "../components/Login";
// import Logout from "../components/Logout";
// import { ProtectedRoute } from "./ProtectedRoute";

const Routes = () => {

    // const handleScrollToTop = (e) => {
    //     e.preventDefault();

    //     // Configuration
    //     const config = {
    //         scrollSpd   : 50, // Pixels / second
    //         ease        : 0.1,
    //         fps         : 30, // Frames / second
    //         distance    : Number(window.scrollY.toFixed(0)),
    //     };

    //     // Loop till reaching TOP
    //     let now;
    //     let then = Date.now();
    //     const interval = 1000 / config.fps;
    //     let delta;
    //     let reqAnim;
    //     let prevX = 0, prevY = 0;

    //     const animate = () => {
    //         reqAnim = requestAnimationFrame(animate);

    //         now = Date.now();
    //         delta = now - then;

    //         if (delta > interval) {
    //             // Correct for the time difference
    //             then = now - (delta % interval);

    //             // Update position
    //             const posX = Number(window.scrollX.toFixed(0));
    //             const posY = Number(window.scrollY.toFixed(0));

    //             // Animation code
    //             if (posY > 0) {
    //                 window.scrollTo(posX, posY - config.scrollSpd);
    //             }

    //             // Stop animation when at the top
    //             // OR
    //             // when user scrolled
    //             if (posY <= 0 || prevY !== 0 && prevY - posY !== config.scrollSpd) {
    //                 cancelAnimationFrame(reqAnim);
    //             }

    //             prevX = posX;
    //             prevY = posY;
    //         }
    //     };
    //     animate();
    // };

    // const HeaderLayout = () => (
    //     <>
    //         <Nav />
    //         <div className="page">
    //             <Outlet />
    //         </div>
    //         <Footer />
    //         <Link id="scrollToTop" onClick={handleScrollToTop}>
    //             <FaChevronUp />
    //         </Link>
    //     </>
    // );

    const ROLES = {
        "User": 2001,
        "Admin": 5150
    };

    const HeaderLayout = () => (
        <AuthProvider>
            <Provider store={store}>
                <Nav />
                <div className="page">
                    <Suspense fallback={<Loading />}>
                        <Outlet />
                    </Suspense>
                </div>
                <ScrollToAnchor />
                {/* <div className="scroll-container">
                <Link to="#top"><FaChevronUp /></Link>
            </div> */}
                <Footer />
            </Provider>
        </AuthProvider>
    );

    // Define public routes accessible to all users
    const routesForPublic = [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/feestjes',
            element: <Feestjes />
        },
        {
            path: '/drop&shop',
            element: <DropShop API_URL={API_URL} />
        },
        // {
        //     path: '/knutseldag',
        //     element: <Knutseldag />
        // },
        {
            path: '/over_ons',
            element: <OverOns />
        },
        {
            path: '/kampjes',
            element: <Kampjes />
        },
        {
            path: '/kampjes/:kamp',
            element: <KampDetails API_URL={API_URL} />
        },
        {
            path: '/checkout',
            element: <CheckOut />
        },
        {
            // https://bambinolier.be/ordercomplete?orderId=2355480447Xd08f2&orderStatusId=100&paymentSessionId=2355480447
            path: '/ordercomplete',
            element: <OrderComplete />
        },
        {
            path: '/unauthorized',
            element: <Unauthorized />
        }
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticationOnly = [
        {
            element: <PersistLogin />,
            children: [
                {
                    path: '/account',
                    element: <Account />,
                    children: [
                        {
                            element: <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />,
                            children: [
                                {
                                    path: '/account/overzicht',
                                    element: <BestelOverzicht />
                                },
                                {
                                    path: '/account/profiel',
                                    element: <Profile />
                                },
                                {
                                    path: '/account/kids',
                                    element: <Kids />
                                }
                            ]
                        },
                        {
                            element: <RequireAuth allowedRoles={[ROLES.Admin]} />,
                            children: [
                                {
                                    path: '/account/admin/agenda',
                                    element: <AdminAgenda API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/kampjes',
                                    element: <AdminKamp API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/kampdagen',
                                    element: <AdminKampDagen API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/nieuwsbrief',
                                    element: <h2 className="center">Nieuwsbrief</h2>
                                },
                                {
                                    path: '/account/admin/instellingen',
                                    element: <h2 className="center">Instellingen</h2>
                                },
                            ]
                        }
                    ]
                },
                {
                    path: '/cart',
                    element: <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />,
                    children: [
                        {
                            path: '/cart',
                            element: <ShopCartList />
                        }
                    ]
                },
            ]
        }
    ];

    // // Define routes accessible only to authenticated users
    // const routesForAuthenticationOnly = [
    //     {
    //         path: '/',
    //         element: <ProtectedRoute />,
    //         children: [
    //             {
    //                 path: '/',
    //                 element: width < 768 ? <Mobile /> : width < 992 ? <Tablet /> : <Laptop />
    //             },
    //             {
    //                 path: '/order/:id',
    //                 element: <Order />
    //             },
    //             {
    //                 path: '/logout',
    //                 element: <Logout />
    //             }
    //         ]
    //     }
    // ];

    // // Define routes accessible only to non-authenticated users
    // const routesForNotAuthenticatedOnly = [
    //     {
    //         path: '/',
    //         element: <h1>Home</h1>
    //     },
    //     {
    //         path: '/login',
    //         element: <Login />
    //     }
    // ];

    const router = createBrowserRouter([
        {
            element: <HeaderLayout />,
            children: [
                ...routesForPublic,
                ...routesForAuthenticationOnly,
                // ...(!token ? routesForNotAuthenticatedOnly: []),
                // ...routesForAuthenticationOnly,
                { // Catch All
                    path: '*',
                    element: <Missing />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}

export default Routes;